body {
  background-color: white;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label {
  color: rgb(108, 108, 108);
}
table,
tr {
  width: 100%;
  text-align: center;
}
a {
  text-decoration: none;
}
footer {
  font-weight: 600;
}
/*  */
/*  */
/* FONT */
/*  */
/*  */
.courgette-regular {
  font-family: "Rouge Script", serif;
  font-weight: 400;
  font-style: normal;
}
.bad-script-regular {
  font-family: "Philosopher", serif;
  font-weight: 400;
  font-style: normal; 
}
/*  */
/*  */
/* HOME */
/*  */
/*  */
.background-home {
  background-color: transparent;
  height: auto;
  width: 100%;
}
.btn-ngga:hover {
  display: none;
  content: "OK";
}
.btn-webew-home {
  padding: 10px 0 10px 0;
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(
    to top,
    rgb(11, 97, 255) 20%,
    rgb(99, 154, 255) 100%
  );
  color: rgb(255, 255, 255);
  font-weight: 600;
  box-shadow: 0 4px 15px 0 rgba(211, 230, 255, 0.13),
    0 8px 20px -5px rgb(81, 142, 255);
  transition: all 0.7s;
}
.btn-webew-home:focus {
  color: white !important;
}
.btn-webew-home:hover {
  border: none;
  background-image: linear-gradient(
    to top,
    rgb(0, 86, 247) 20%,
    rgb(92, 148, 253) 100%
  );
  color: rgb(255, 255, 255);
  transform: scale(0.9);
}
.img-webew-home {
  padding: 40px 0 0 0;
}
.img-bg-lengkung {
  margin: 20px 0 0 0;
}
.img-bg-lengkung-bawah {
  margin: -20px 0 50px 0;
  transform: scaleY(-1);
}
.link-img-webew-home {
  cursor: default;
}
.title-webew-home {
  margin: 11vw 0 20px 0;
  font-weight: 700;
  text-align: center;
}
.text-webew-home {
  margin: 0 0 30px 0;
  font-weight: 600;
  font-size: 12pt;
  text-align: center;
}
/*  */
/*  */
/* ABOUT */
/*  */
/*  */
#about {
  padding-top: 60px;
}
.judul-about {
  font-weight: 700;
  color: rgb(108, 108, 108);
  margin: 9vw 0 20px 0;
}
.teks-about {
  font-weight: 600;
}
/*  */
/*  */
/* PRODUK */
/*  */
/*  */
#bikinCV {
  padding-top: 60px;
}
#formCV {
  padding-top: 60px;
}
#produk {
  margin: 100px 0 100px 0;
}
#undanganDigital {
  padding-top: 60px;
}
#ucapanUltah {
  padding-top: 60px;
}
#website {
  padding-top: 60px;
}
.btn-webew-product {
  width: 100%;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(
    to top,
    rgb(11, 97, 255) 20%,
    rgb(99, 154, 255) 100%
  );
  color: rgb(255, 255, 255);
  font-weight: 600;
  box-shadow: 0 4px 15px 0 rgba(211, 230, 255, 0.13),
    0 8px 20px -8px rgb(81, 142, 255);
  transition: all 0.7s;
}
.btn-webew-product:focus {
  color: white !important;
}
.btn-webew-product:hover {
  border: none;
  background-image: linear-gradient(
    to top,
    rgb(0, 86, 247) 20%,
    rgb(92, 148, 253) 100%
  );
  color: rgb(255, 255, 255);
  transform: scale(0.9);
}
.box {
  border: none;
  border-radius: 20px;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.box-kodam {
  background-image: url("../public/img/images.png"); /* Sesuaikan jalur gambar */
  background-size: cover; /* Agar gambar menutupi seluruh elemen */
  background-position: center;
  border: none;
  border-radius: 20px;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.card {
  border: none;
  border-radius: 20px;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.card-text {
  font-size: 11pt;
}
.card-title {
  color: rgb(108, 108, 108);
}
.card-text-harga {
  font-size: 10pt;
  font-weight: 600;
  margin-bottom: 5px;
}
.card-text-rupiah {
  font-size: 13pt;
  font-weight: 600;
  color: rgb(42, 116, 255);
}
.form {
  margin: 7px 0 0 0;
  border-radius: 20px;
  border: none;
  border: 1px solid rgb(229, 229, 229);
  box-shadow: 0 10px 15px 0 rgba(235, 237, 244, 0.471);
}
.form:focus {
  box-shadow: none;
  border: 1px solid rgb(214, 214, 214);
}
.form-disable {
  visibility: hidden;
  position: fixed;
  height: 0;
}
.biShop {
  font-size: 30px;
  margin-left: 5px;
  color: rgb(42, 116, 255);
}
/*  */
/*  */
/* CREDIT */
/*  */
/*  */
.judul-credit {
  font-weight: 700;
  color: rgb(108, 108, 108);
  margin: -10px 0 20px 0;
}
.card {
  font-weight: 600;
  font-size: 10pt;
  transition: all 0.5s;
}
.card:hover {
  transition: all 0.5s;
  transform: scale(0.9);
}
/*  */
/*  */
/* SCROLLBAR */
/*  */
/*  */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: #b6bec2;
}
/*  */
/*  */
/* Login */
/*  */
/*  */
.box-login {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  padding: 30px 30px 20px 30px;
  margin-top: 12vh;
  border-radius: 30px;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.btn-webew-login {
  padding: 10px 0 10px 0;
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(
    to top,
    rgb(11, 97, 255) 20%,
    rgb(99, 154, 255) 100%
  );
  color: rgb(255, 255, 255);
  font-weight: 600;
  box-shadow: 0 4px 15px 0 rgba(211, 230, 255, 0.13),
    0 8px 20px -5px rgb(81, 142, 255);
  transform: scale(0.9);
  transition: all 0.7s;
}
.btn-webew-login:focus {
  color: white !important;
}
.btn-webew-login:hover {
  border: none;
  background-image: linear-gradient(
    to top,
    rgb(0, 86, 247) 20%,
    rgb(92, 148, 253) 100%
  );
  color: rgb(255, 255, 255);
  transform: scale(0.8);
}
/*  */
/*  */
/* TOOL LAIN */
/*  */
/*  */
.accordion-item {
  border: none;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
  border-bottom: 2px solid rgb(229, 229, 229);
}
.accordion-button:not(.collapsed) {
  color: rgb(108, 108, 108);
  border-top: 2px solid rgb(229, 229, 229);
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.accordion-button:focus {
  box-shadow: none;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
}
.accordion-button {
  color: rgb(108, 108, 108);
  font-weight: 600;
  box-shadow: 5px 5px 18px 5px rgba(211, 215, 241, 0.507);
  margin-top: 20px;
}
.accordion-body {
  padding: 0 20px 30px 20px;
}
/*  */
/*  */
/* UCAPAN ULTAH */
/*  */
/*  */
#loader {
  animation: move 4s;
}
@keyframes move {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
/*  */
/*  */
/* LOADER PAGE */
/*  */
/*  */
.loader-page-bg-2 {
  height: 100vh;
  width: 100%;
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-page-bg {
  background-color: #282a3a8b;
  backdrop-filter: blur(10px);
  height: 100vh;
  width: 100%;
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-page {
  border: 20px solid #ffffff;
  border-radius: 50%;
  border-top: 20px solid rgb(11, 97, 255);
  width: 150px;
  height: 150px;
  animation: spinner 0.8s linear infinite;
}
.loader-page-2 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spinner 0.8s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  */
/*  */
/* BUNGA COVER WE PERNIKAHAN */
/*  */
/*  */
.bunga-cover{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  animation: shrink-grow 2.5s infinite ease-in-out;
}
@keyframes shrink-grow {
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
}
/*  */
/*  */
/* GALLERY WE PERNIKAHAN */
/*  */
/*  */
* {
  box-sizing: border-box;
}
.rowes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
}
.column {
  -ms-flex: 25%;
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}
.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}
@media screen and (max-width: 5000px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 200px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
/*  */
/*  */
/* MOVE IMAGE PAJANGAN WE PERNIKAHAN */
/*  */
/*  */
.moving-image-1, .moving-image-2, .moving-image-3, .moving-image-4, .moving-image-5,.moving-image-6, .moving-image-7, .moving-image-8, .moving-image-9, .moving-image-10 {
  z-index: 2;
  position: absolute;
}
.moving-image-1 {
  width: 38px;
  animation: moveUp 10s linear infinite, shrinkX 0.9s linear infinite;
  left: 15%;
}
.moving-image-2 {
  width: 36px;
  animation: moveUp 13s linear infinite, shrinkX 1.1s linear infinite;
  right: 17%;
}
.moving-image-3 {
  width: 35px;
  animation: moveUp 8s linear infinite, shrinkX 0.7s linear infinite;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.moving-image-4 {
  width: 36px;
  animation: moveUp 15s linear infinite, shrinkX 1.3s linear infinite;
  left: 0%;
}
.moving-image-5 {
  width: 38px;
  animation: moveUp 20s linear infinite, shrinkX 1.5s linear infinite;
  right: 0%;
}
/*  */
.moving-image-6 {
  width: 38px;
  animation: moveDown 12s linear infinite, rotate 1.4s linear infinite;
  left: 15%;
}
.moving-image-7 {
  width: 36px;
  animation: moveDown 15s linear infinite, rotate 1.6s linear infinite;
  right: 17%;
}
.moving-image-8 {
  width: 35px;
  animation: moveDown 10s linear infinite, rotate 1.2s linear infinite;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.moving-image-9 {
  width: 36px;
  animation: moveDown 17s linear infinite, rotate 1.8s linear infinite;
  left: 3%;
}
.moving-image-10 {
  width: 38px;
  animation: moveDown 22s linear infinite, rotate 2s linear infinite;
  right: 3%;
}

@keyframes moveUp {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 100%;
  }
}
@keyframes moveDown {
  0% {
    top: 0px;
  }
  100% {
    top: 90%;
  }
}
@keyframes shrinkX {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/*  */
/*  */
/* LAYAR */
/*  */
/*  */
@media screen and (min-width: 850px) {
  .marginTopWePernikahan{
    padding-top: 55vh;
  }
}
@media screen and (max-width: 850px) {
  .marginTopWePernikahan{
    padding-top: 70vh;
  }
}
@media screen and (max-width: 576px) {
  .marginTopWePernikahan{
    padding-top: 63vh;
  }
}
@media screen and (max-width: 400px) {
  .marginTopWePernikahan{
    padding-top: 60vh;
  }
}
@media screen and (max-width: 360px) {
  .marginTopWePernikahan{
      padding-top: 57vh;
  }
}
@media screen and (max-width: 330px) {
  .marginTopWePernikahan{
      padding-top: 45vh;
  }
}
/*  */
/*  */
/* CV */
/*  */
/*  */
/* CVG1 */
.table-cvg1, .tr-cvg1{
  width: 100%;
  border: none;
}
.wrapper-cvg1{
  margin-top: -5px;
  height: 22.5cm;
  width: 157.5mm;
  padding: 10px;
}
.dasar-cvg1{
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10px;
  border: 3px solid black;
}
/* BOX */
.box-judul-cv-cvg1{
  height: 3.6cm;
  width: 10cm;
  margin: 15px 0 0 12px;
  background-color: transparent;
}
.box-foto-cvg1{
  height: 3.8cm;
  width: 4.5cm;
  margin: 15px 0 0 0;
  background-color: transparent;
}
.pas-foto-cvg1{
  float: right;
  margin: 0 -12px 0 0;
  min-height: 3.8cm;
  max-height: 3.8cm;
  width: auto;
}
.box-datapribadi-cvg1{
  max-height: 7cm;
  width: 100%;
  margin: 5px 0 9px 0;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pengalamankerja-cvg1{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pendidikan-cvg1{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-keahlian-cvg1{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
/* SHAPE */
.garis-cvg1{
  width: 100%;
  height: 3px;
  background-color: black;
}
/* TEKS */
.teks-color-cvg1{
  color: black;
  font-size: 3.2mm;
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
}
.text-ceritadiri-cvg1{
  font-size: 3.2mm;
  font-weight: 500;
  margin: 17px 0 0 -6px;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.text-pengalamankerja-cvg1{
  margin-top: -10px;
  font-weight: 500;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.judul-cv-cvg1{
  font-size: 8mm;
  font-weight: 700;
  margin: -12px 0 0 -13px;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.judul-datapribadi-cvg1{
  margin-top: -7.3px;
  font-size: 5mm;
  font-weight: 700;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
/* CVG1 */
/*  */
/*  */
/* CVG2 */
.table-cvg2, .tr-cvg2{
  width: 100%;
  border: none;
}
.wrapper-cvg2{
  margin-top: -5px;
  height: 22.5cm;
  width: 157.5mm;
  padding: 10px;
}
.dasar-cvg2{
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10px;
  border: 3px solid black;
}
/* BOX */
.box-judul-cv-cvg2{
  height: 3.6cm;
  width: 100%;
  margin: 15px 0 0 0;
  background-color: transparent;
}
.box-foto-cvg2{
  height: 4cm;
  width: 4.5cm;
  margin: 0px 0 0 0;
  background-color: transparent;
}
.pas-foto-cvg2{
  float: right;
  margin: 0 -12px 0 0;
  min-height: 4cm;
  max-height: 4cm;
  width: auto;
}
.box-datapribadi-cvg2{
  max-height: 7cm;
  width: 10cm;
  margin: 0 0 0 12px;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-datapribadi2-cvg2{
  max-height: 7cm;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pengalamankerja-cvg2{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pendidikan-cvg2{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-keahlian-cvg2{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
/* SHAPE */
.garis-cvg2{
  width: 100%;
  height: 3px;
  background-color: black;
}
/* TEKS */
.teks-color-cvg2{
  color: black;
  font-size: 3.2mm;
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
}
.text-ceritadiri-cvg2{
  font-size: 3.2mm;
  font-weight: 500;
  margin: 17px 0 0 -6px;
  color: black;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.text-pengalamankerja-cvg2{
  margin-top: -10px;
  font-weight: 500;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.judul-cv-cvg2{
  font-size: 8mm;
  font-weight: 700;
  margin: -12px 0 0 -13px;
  color: black;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.judul-datapribadi-cvg2{
  padding-top: 7px;
  font-size: 5mm;
  font-weight: 700;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
/* CVG2 */
/*  */
/*  */
/* CVG3 */
.table-cvg3, .tr-cvg3{
  width: 100%;
  border: none;
}
.wrapper-cvg3{
  margin-top: -5px;
  height: 22.5cm;
  width: 157.5mm;
  padding: 10px;
}
.dasar-cvg3{
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10px;
  border: 3px solid black;
}
/* BOX */
.box-judul-cv-cvg3{
  height: 3.6cm;
  width: 100%;
  margin: 15px 0 0 0;
  background-color: transparent;
}
.box-foto-cvg3{
  height: 4cm;
  width: 4.5cm;
  margin: 0px 0 0 0;
  background-color: transparent;
}
.pas-foto-cvg3{
  float: right;
  margin: 0 -12px 0 0;
  min-height: 4cm;
  max-height: 4cm;
  width: auto;
}
.box-datapribadi-cvg3{
  max-height: 7cm;
  width: 10cm;
  margin: 0 0 0 12px;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-datapribadi2-cvg3{
  max-height: 7cm;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pengalamankerja-cvg3{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pendidikan-cvg3{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-keahlian-cvg3{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
/* SHAPE */
.garis-cvg3{
  width: 100%;
  height: 3px;
  background-color: black;
}
/* TEKS */
.teks-color-cvg3{
  color: black;
  font-size: 3.2mm;
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
}
.text-ceritadiri-cvg3{
  font-size: 3.2mm;
  font-weight: 500;
  margin: 17px 0 0 -6px;
  color: black;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.text-pengalamankerja-cvg3{
  margin-top: -10px;
  font-weight: 500;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.judul-cv-cvg3{
  font-size: 8mm;
  font-weight: 700;
  margin: -12px 0 0 -13px;
  color: black;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.judul-datapribadi-cvg3{
  font-size: 5mm;
  font-weight: 700;
  color: black;
  text-align: center;
  border: 2px solid black;
  font-family: 'Times New Roman', Times, serif;
}
/* CVG3 */
/*  */
/*  */
/* CVG4 */
.table-cvg4, .tr-cvg4{
  width: 100%;
  border: none;
}
.wrapper-cvg4{
  margin-top: -5px;
  height: 22.5cm;
  width: 157.5mm;
  padding: 10px;
}
.dasar-cvg4{
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10px;
  border: 3px solid black;
}
/* BOX */
.box-judul-cv-cvg4{
  height: 3.6cm;
  width: 100%;
  margin: 15px 0 0 0;
  background-color: transparent;
}
.box-foto-cvg4{
  height: 4cm;
  width: 4.5cm;
  margin: 0px 0 0 0;
  background-color: transparent;
}
.pas-foto-cvg4{
  float: right;
  margin: 0 -12px 0 0;
  min-height: 4cm;
  max-height: 4cm;
  width: auto;
}
.box-datapribadi-cvg4{
  max-height: 7cm;
  width: 10cm;
  margin: 0 0 0 12px;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-datapribadi2-cvg4{
  max-height: 7cm;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pengalamankerja-cvg4{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-pendidikan-cvg4{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
.box-keahlian-cvg4{
  max-height: 2.4cm;
  width: 100%;
  padding: 0 5px 0 5px;
  font-weight: 500;
  background-color: transparent;
}
/* SHAPE */
.garis-cvg4{
  width: 100%;
  height: 3px;
  background-color: black;
}
/* TEKS */
.teks-color-cvg4{
  color: black;
  font-size: 3.2mm;
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
}
.text-ceritadiri-cvg4{
  font-size: 3.2mm;
  font-weight: 500;
  margin: 17px 0 0 -0;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.text-pengalamankerja-cvg4{
  margin-top: -10px;
  font-weight: 500;
  color: black;
  font-family: 'Times New Roman', Times, serif;
}
.judul-cv-cvg4{
  font-size: 8mm;
  font-weight: 700;
  margin: -12px 0 0 0;
  color: black;
  text-decoration: underline;
  font-family: 'Times New Roman', Times, serif;
}
.judul-datapribadi-cvg4{
  padding-top: 8px;
  font-size: 5mm;
  font-weight: 700;
  color: black;
  text-decoration: underline;
  font-family: 'Times New Roman', Times, serif;
}
/* CVG4 */